.fadeIn {
    animation: 0.2s fadeIn cubic-bezier(0.6, -0.28, 0.74, 0.05);
  }
  
  .fadeOut {
    animation: 0.2s fadeOut linear;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: .5;
    }
    to {
      opacity: 0;
    }
  }
  