.phone-background-container {
  width: 238px;
  height: 510px;
  background-image: url('../../../../../assets/images/Iphone\ 12\ Flat\ -\ Black.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.phone-container {
  width: 221px;
  height: 455px;
  border-radius: 36px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position:absolute;
  position: absolute;
  top: 7%;
  left: 3%;

}

.phone-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2; /* This ensures that the header is above the media */
}

.brand-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 3px solid #2794F9;
  padding: 3px;
  background-color: white;
  box-sizing: border-box;
}

.brand-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #2794F9;
  overflow: hidden;
  /* padding: 8px; */
}

.brand-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 50%;
}

.story-media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Take full height */
  z-index: 1; /* Below the header but above the background */
  object-fit: cover; /* Cover the entire area without stretching */
}



.navigation-buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 3;
}

.phone-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
    position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2; /* This ensures that the footer is above the media */
}

.offer-button {
  background-color: #2794F9;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  margin-top: 8px;
  cursor: pointer;
}

.arrow-icon {
  width: 60px;
  height: 24px;
  margin-top: 8px;
  resize: contain;
}

